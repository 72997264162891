import React from "react";
import { graphql, PageProps } from "gatsby";

import { Layout } from "components";
import { PostPageByCategoryQuery } from "codegen";
import { Hero, PostList } from "components/ACF";

interface CategoryArchivePageContext {
  numberOfPages: number;
  previousPagePath: string;
  nextPagePath: string;
  currentPageNumber: number;
}

const CategoryArchiveTemplate = ({
  pageContext,
  data,
  location,
}: PageProps<PostPageByCategoryQuery, CategoryArchivePageContext>): JSX.Element => {
  const { numberOfPages, currentPageNumber } = pageContext;
  const { post, posts, categories } = data;
  const { seo, name } = post;

  const allPosts = posts.nodes.map((node) => node);
  const allCategories = categories.nodes.map((node) => node);

  return (
    <Layout seo={seo}>
      <>
        <Hero
          data={{
            content: `<h1>${name}</h1>`,
            compact: true,
            breadcrumbs: "none",
            image: post?.featuredImage?.image,
          }}
          location={seo.breadcrumbs}
        />
        <PostList
          data={{
            currentPageNumber,
            numberOfPages,
          }}
          cptItems={allPosts}
          cptCategories={allCategories}
          cptItems={posts.nodes}
          browserLocation={location}
        />
      </>
    </Layout>
  );
};

export default CategoryArchiveTemplate;

export const CategoryArchivePageQuery = graphql`
  query PostPageByCategory($id: String!, $offset: Int!, $postsPerPage: Int!) {
    post: wpCategory(id: { eq: $id }) {
      id
      slug
      uri
      name
      featuredImage {
        image {
          title
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 80, placeholder: BLURRED, width: 1800)
            }
          }
        }
      }
      seo {
        title
        fullHead
        metaRobotsNofollow
        metaRobotsNoindex
        breadcrumbs {
          text
          url
        }
      }
    }
    categories: allWpCategory {
      nodes {
        id
        name
        slug
        uri
      }
    }
    posts: allWpPost(
      sort: { fields: [date], order: DESC }
      filter: { categories: { nodes: { elemMatch: { id: { eq: $id } } } } }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        ...PostFields
      }
    }
  }
`;
